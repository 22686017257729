import React from 'react'
import skull from '../assets/imgs/skullhead.png'
import circtxt from '../assets/imgs/circtxt.svg'
import { motion } from 'framer-motion'
import { FaTelegramPlane, FaEthereum } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

import { IconContext } from 'react-icons';

export const Footer = () => {
    return (
        <div className='footer'>

            <div className="left-logo white ocr">
                <p className="biggest">©2023 SKYNET AI TOKEN</p>
                <p className="big">Neural Meme Based Artificial intelligence</p>
                <p className="sm">
                    this is a meme coin and it is not affiliated with the terminator franchise. <br></br><br></br>
                    The token is for entertainment purposes only.
                </p></div>
            <IconContext.Provider value={{ size: "1.4rem" }}>

                <div className="left-mod-icons">
                    <a href='https://twitter.com/SkyNetToken' className="icon" target="_blank" rel='noreferrer'><FaXTwitter /></a>
                    <a href='https://t.me/SkyNet_Portal1' className="icon" target='_blank' rel='noreferrer'><FaTelegramPlane /></a>
                    <a href='https://etherscan.io/address/0x84658f225ff8a36c864C92428dBa30D930FDf35a' className="icon"
                        target='_blank' rel='noreferrer'><FaEthereum /></a>
                </div>
            </IconContext.Provider>
            <div className="middle-logo">
                <img src={skull} alt="skull" className='skull' />
                <motion.img src={circtxt} alt="circtxt" className="circtxt"
                    animate={{ rotate: 360 }}
                    transition={{ duration: 7, repeat: Infinity, ease: 'linear' }} />
            </div>
        </div>
    )
}
