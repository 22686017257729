import { useState, useEffect } from "react";
import { Aboutarea } from "./components/Aboutarea";
import { Footer } from "./components/Footer";
import { Navbar } from "./components/Navbar";
import { Tokenomicsarea } from "./components/Tokenomicsarea";
import { Toparea } from "./components/Toparea";
import { Loadscreen } from "./components/Loadscreen";

function App() {


  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoading(false), 6000)
  }, [])


  return (
    <div className="App">

      <Loadscreen loading={loading} />

      <Navbar />
      <Toparea />
      <Aboutarea />
      <Tokenomicsarea />
      <Footer />


    </div>
  );
}

export default App;
