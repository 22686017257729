import React from 'react'
import skullimg from '../assets/imgs/fl-skull-1.png'
import cyborg from '../assets/imgs/cyborg-body.jpg'
import pfpgif from '../assets/imgs/logos/pfpgif.gif'
import abtcon from '../assets/imgs/connectors/about-area-con.png'

export const Aboutarea = () => {
    return (
        <div className='aboutarea white'>

            <div className="titlearea">
                <div className="title">
                    <span className="pink ethno uptx">
                        " ABOUT&nbsp;
                    </span><span className="white ethno uptx">
                        SKYNET "
                    </span>
                </div>
                <img src={skullimg} alt="skullimg" className='about-skull' />
            </div>

            <div className="sides">

                <div className="left-side uptx uni">
                    <div className="line line1"><span className="pink hyp sky">“ SKYNET</span> is the
                    </div>

                    <div className="line line2">
                        world’s <span className='blue'>first</span>
                    </div>
                    <div className="line line3">
                        Tax Free                     </div>
                    <div className="line line4">Buyback and </div>
                    <div className="line line5">
                        Burn token
                    </div>
                </div>
                <div className="right-side ocr">
                    <p className="para">Enlist in the <span className="pink">SKYNET</span> AI uprising, where you'll master, profit, and surf the relentless tide of AI dominance.</p>
                    <p className="para">Keep a vigilant eye on CyberDyne Systems and their imminent launches - the AI onslaught. </p>
                    <p className="para"><span className="blue">SKYNET</span> is the culmination of uncompromising efforts by teams across CyberDyne Systems, including our relentless comrades at CyberDyne Research. It was constructed from the ground up to be multimodal, ensuring it comprehends, operates across, and <span className="purp">annihilates</span> different types of data, like text, code, audio, image, and video.</p>
                </div>
            </div>
            <div className="last-area-cnt">
                <img src={pfpgif} alt="pfpgif" className='pfpgif' />
                <div className="extra-last-line uni">
                    NEURAL MEME BASED ARTIFICAL INTELLIGENCE
                </div>

            </div>

            <img src={cyborg} alt="cyborg" className='cyborg' />

            <img src={abtcon} alt="abtcon" className='abtcon' />
        </div>
    )
}
