import React, { useState, useEffect } from 'react'
import skullimg from '../assets/imgs/fl-skull-1.png'
// import skytri from '../assets/imgs/skynet-txt-triangle.png'
import leftline from '../assets/imgs/lineupdown.svg'
import rightline from '../assets/imgs/lineside.svg'
import navcon from '../assets/imgs/connectors/nav-con.png'
import { RiMenu4Fill } from "react-icons/ri";
import { GiSplitCross } from "react-icons/gi";

import { IconContext } from 'react-icons';
import { motion, AnimatePresence } from 'framer-motion'

export const Navbar = () => {

    const contractAddress = "0x84658f225ff8a36c864C92428dBa30D930FDf35a";
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const updateIsMobile = () => {
            setIsMobile(window.innerWidth <= 1200);
        };

        updateIsMobile();


        window.addEventListener('resize', updateIsMobile);

        return () => {
            window.removeEventListener('resize', updateIsMobile);
        };


    }, []);





    const [navbarPopup, setNavbarpopup] = useState(false);


    return (
        <div className='navbar white'>

            <img src={leftline} alt="leftarea" className='leftline' />
            <img src={rightline} alt="leftarea" className='rightline' />

            <img src={navcon} alt="navcon" className='navcon' />
            <div className="leftarea">
                <img src={skullimg} alt="skullimg" className='skullimg' />
                <div className="contractarea ocr">
                    <p className="smtxt">contract address</p>
                    <p className="uptx ocr catxt pink">{contractAddress}</p>
                    <p className="smtxt sm2">sol contract address</p>
                    <p className="uptx ocr catxt pink">7c6FaEd7sWKCTekinyhDNNLrZ2LHbhPFxarL7ZLR4YXp</p>

                </div>
            </div>
            {isMobile ? (

                <IconContext.Provider value={{ size: "2rem" }}>
                    {
                        !navbarPopup ? <RiMenu4Fill className='menu' onClick={() => setNavbarpopup(true)} />
                            : <GiSplitCross onClick={() => setNavbarpopup(false)} className='menu' />
                    }

                </IconContext.Provider>

            ) : (
                <div className="rightarea uptx ocr">
                    <div className="links">
                        <a href="https://www.dextools.io/app/en/ether/pair-explorer/0x2c0c82bfca70e2f19341776b746732b7c6674054" target='_blank' rel='noreferrer'>dextools weth</a>
                        <a href="https://www.dextools.io/app/en/ether/pair-explorer/0x963b7a20cb99683c2cafc85b7328e9aba3f57a27" target='_blank' rel='noreferrer'>dextools usdc</a>
                        <a href="https://dexscreener.com/solana/7c6FaEd7sWKCTekinyhDNNLrZ2LHbhPFxarL7ZLR4YXp" target='_blank' rel='noreferrer'>dexscreener sol</a>
                        {/* <a href="https://t.me/SkyNet_Portal1" target='_blank' rel='noreferrer'>telegram</a> */}
                        <a href="https://twitter.com/SkyNetToken" target='_blank' rel='noreferrer'>twitter</a>
                    </div>
                    <div className="bent-button-cnt">
                        <a href="https://t.me/SkyNet_Portal1" target='_blank' rel='noreferrer'>
                            <div className="main-cnt uptx">
                                <p className="txt">
                                    community
                                </p></div>
                        </a>
                    </div>

                </div>
            )}
            <AnimatePresence>

                {navbarPopup && <motion.div className="navlist ocr" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}

                >
                    <IconContext.Provider value={{ size: "2rem", fill: "white" }}>

                        <GiSplitCross onClick={() => setNavbarpopup(false)} className='close-btn' />
                    </IconContext.Provider>
                    <div className="links">
                        <a href="https://www.dextools.io/app/en/ether/pair-explorer/0x2c0c82bfca70e2f19341776b746732b7c6674054" target='_blank' rel='noreferrer'>dextools weth</a>
                        <a href="https://www.dextools.io/app/en/ether/pair-explorer/0x963b7a20cb99683c2cafc85b7328e9aba3f57a27" target='_blank' rel='noreferrer'>dextools usdc</a>
                        <a href="https://dexscreener.com/solana/7c6FaEd7sWKCTekinyhDNNLrZ2LHbhPFxarL7ZLR4YXp" target='_blank' rel='noreferrer'>dexscreener sol</a>
                        <a href="https://t.me/SkyNet_Portal1" target='_blank' rel='noreferrer'>telegram</a>
                        <a href="https://twitter.com/SkyNetToken" target='_blank' rel='noreferrer'>twitter</a>
                    </div>
                    <div className="bent-button-cnt">
                        <a href="https://t.me/SkyNet_Portal1" target='_blank' rel='noreferrer'>
                            <div className="main-cnt uptx">
                                <p className="txt">
                                    community
                                </p></div>
                        </a>
                    </div>
                    <p className="uptx ocr catxtmob pink">{contractAddress}</p>

                    <p className="uptx ocr catxtmob2 pink">7c6FaEd7sWKCTekinyhDNNLrZ2LHbhPFxarL7ZLR4YXp</p>


                </motion.div>
                }
            </AnimatePresence>
        </div>
    )
}
