import React from 'react'
import salut from '../assets/imgs/logos/salut-skull.png'
import chakra from '../assets/imgs/chakra.png'
import tokenomics from '../assets/imgs/tokenomics.gif'
import { motion } from 'framer-motion'

export const Tokenomicsarea = () => {
    return (
        <div className='tokenomicsarea gridbg'>
            <div className="toplogo">
                <img src={salut} alt="salut" className='salut' />
            </div>

            <div className="three-rows">

                <div className="left">
                    <motion.img src={chakra} alt="chakra" className='chakra'
                        animate={{ rotate: 360 }}
                        transition={{ duration: 1.5, repeat: Infinity, ease: 'linear' }}
                    />
                    <div className="date ethno">
                        29.<br></br>
                        <span className="blue">08</span>.<br></br>
                        1997.
                    </div>

                    <div className="sentient uni">
                        <p className="line">total<br></br>supply</p>
                        <p className="line">the date</p>
                        <p className="line blue">SKYNET</p>
                        <p className="line"><span className="pink">Attacked</span></p>
                    </div>

                </div>



                <div className="middle">
                    <p className="bigtitle ethno">TOKENOMICS</p>
                    <img src={tokenomics} alt="tokigif" className='tokigif' />
                    <div className="data-cnt">

                        <p className="ethno uptx data-tx">data</p>


                        <div className='tokenomics-list ocr'>
                            <p className='item'>Symbol - <span className="pink">$SKYNET</span> </p>
                            <p className='item'>Network - <span className="pink">ERC20</span> </p>
                            <p className='item'>Total Supply - <span className="pink">29081997</span> </p>
                            <p className='item'>Taxes - <span className="pink">0/0</span> </p>
                            <p className='item'>Liquidity Pool - <span className="pink">locked</span> </p>
                            <a href='https://etherscan.io/address/0x84658f225ff8a36c864C92428dBa30D930FDf35a' target='_blank' rel='noreferrer' className='item'>Contract Address - <span className="pink ">click here</span> </a>
                        </div>


                        <div className="bent-button-cnt checkon-scanbtn">
                            <a href='https://etherscan.io/address/0x84658f225ff8a36c864C92428dBa30D930FDf35a' target='_blank' rel="noreferrer" className="main-cnt uptx">
                                <p className="txt ocr">
                                    Check On Etherscan
                                </p></a>
                        </div>


                        <div className="first-3-lang uni uptx">
                            <p className="lang"><span className="blue">first</span> in the world</p>
                            <p className="lang">der <span className="purp">erste</span> auf der <span className="pink">Welt</span></p>
                            <p className="jap pink">우리는 세계 최초입니다</p>
                        </div>



                    </div>
                </div>

                <div className="right">
                    <p className="ethno of">
                        OF
                    </p>
                    <p className="hyp brand uptx">
                        <span className="pink">SKY</span><span className="blue">NET</span>
                    </p>
                </div>

            </div>
        </div>
    )
}
