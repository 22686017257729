import React from 'react'
import skullimg from '../assets/imgs/fl-skull-1.png'
import { motion, AnimatePresence } from 'framer-motion';

export const Loadscreen = ({ loading }) => {
    return (
        <AnimatePresence>
            {loading &&
                <motion.div className='loadingscreen gridbg' key="modal"
                    initial={{ opacity: 1, y: 0 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 1, y: "-100%", transition: { type: 'spring', duration: 4 } }}

                >
                    <img src={skullimg} className="skullimg" alt='skull' />
                    <div className="half-circle-spinner">
                        <div className="circle circle-1"></div>
                        <div className="circle circle-2"></div>
                    </div>            <p className="txt txtcen ocr uptx">resistance is futile</p>

                </motion.div>
            }

        </AnimatePresence>

    )
}
